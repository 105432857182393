/**This file holds the function to send emails */

import emailJs from "@emailjs/browser";




var templateParams = {
  name: "Bruice Berlick",
  notes: "",
  to_email:"chimoyo.bruce@yahoo.com"
};

let SERVICE_ID = process.env.SERVICE_ID
let TEMPLATE_ID =process.env.TEMPLATE_ID 
let PUBLIC_KEY = process.env.PUBLIC_KEY

export const sendEmail = async () => {
    console.log(SERVICE_ID , TEMPLATE_ID , PUBLIC_KEY)
  try {
    let result = await emailJs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY,);

    console.log(result);
  } catch (error) {
    console.log(error);
  }
};
