import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';
import { sendEmail } from '../../utils/SendEmail';




let onSubmit = (e) =>{

  e.preventDefault()
  sendEmail("test_from.com" , "test_variable_2")

  console.log("Submitting ..........")
      
}
const handleChange = (e) =>{
  console.log("hello")
    
}

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}




const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const [formData , setFormData] = useState()
  


  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  return (


   
    <section
      {...props}
      className={outerClasses}
      name="contact"
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Get in touch with us 
              </h3>
          </div>
          <form onSubmit={onSubmit}>
          <div className="cta-action contact-form">
            <Input id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your Email Address" onChange = {handleChange}>
              
            </Input>
            <Input id="newsletter" type="tel" label="Subscribe" labelHidden hasIcon="right" placeholder="Your Phone Number">
              
            </Input>
            <Input id="newsletter" type="textarea" label="Subscribe" labelHidden hasIcon="right" placeholder="Your Message">
              
            </Input>
            <Button type = "submit">
              Submit
            </Button>
          </div>
          </form>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;