import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { Link } from 'react-router-dom';

import { BsGlobe } from 'react-icons/bs';
import { MdOutlineAppSettingsAlt, MdOutlineDesignServices } from 'react-icons/md';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { GoServer } from 'react-icons/go';

const propTypes = {
  ...SectionTilesProps.types
}


const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Discover Our Services',
    paragraph: 'At PinTech, we have a team of proficient individuals in the following fields.'
  };

  //Icon styles
  const serviceIconStyles = { color: "#6D6DFF", margin: "0 5px" };

  //Tiles Item syles
  const itemTileStyles = {
    boxShadow: "rgba(255, 255, 255, 0.3) 0px 1px 2px 0px, rgba(255, 255, 255, 0.15) 0px 2px 6px 2px",
    borderRadius: "5px",
    margin: "25px"
  }

  return (
    <section
      {...props}
      className={outerClasses}
      name="services"
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div style={itemTileStyles} className="tiles-item service-tile service-container-tile reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header service-icon-wrapper">
                  <BsGlobe style={serviceIconStyles} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Web Development
                  </h4>
                  <p className="m-0 text-sm">
                    We develop websites as well as web-based applications using industry-leading standards and technologies.
                  </p>
                  <li>
                    <Link to="#0" className="button explore-button button-primary button-wide-mobile button-sm">Explore</Link>
                  </li>
                </div>
              </div>
            </div>

            <div style={itemTileStyles} className="tiles-item reveal-from-bottom service-container-tile" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header service-icon-wrapper">
                  <MdOutlineAppSettingsAlt style={serviceIconStyles} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Mobile App Development
                  </h4>
                  <p className="m-0 text-sm">
                    Our mobile app developers at PinTech harness the latest technology to build and deliver cross-platform mobile applications
                  </p>
                  <li>
                    <Link to="#0" className="button explore-button button-primary button-wide-mobile button-sm">Explore</Link>
                  </li>
                </div>
              </div>
            </div>

            <div style={itemTileStyles} className="tiles-item reveal-from-bottom service-container-tile" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header service-icon-wrapper">
                  <GoServer style={serviceIconStyles} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Web Hosting
                  </h4>
                  <p className="m-0 text-sm">
                    Host your website or web application with us for smooth operation and customer satisfaction.
                  </p>
                  <li>
                    <Link to="#0" className="button explore-button button-primary button-wide-mobile button-sm">Explore</Link>
                  </li>
                </div>
              </div>
            </div>

            <div style={itemTileStyles} className="tiles-item reveal-from-bottom service-container-tile" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header service-icon-wrapper">
                  <MdOutlineDesignServices style={serviceIconStyles} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Systems Design
                  </h4>
                  <p className="m-0 text-sm">
                    Let us help you design a scalable system architecture that perfectly resembles your business processes.
                  </p>
                  <li>
                    <Link to="#0" className="button explore-button button-primary button-wide-mobile button-sm">Explore</Link>
                  </li>
                </div>
              </div>
            </div>

            <div style={itemTileStyles} className="service-container-tile tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header service-icon-wrapper">
                  <HiOutlineSpeakerphone style={serviceIconStyles} />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Digital Marketing
                  </h4>
                  <p className="m-0 text-sm">
                    Our digital marketing team will push your business forward by enhancing your presence in the digital space.
                  </p>
                  <li>
                    <Link to="#0" className="button explore-button button-primary button-wide-mobile button-sm">Explore</Link>
                  </li>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;