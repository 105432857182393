import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { BsBriefcase, BsInstagram, BsLinkedin } from 'react-icons/bs'
import { BsFacebook } from 'react-icons/bs';
import { BsTwitter } from 'react-icons/bs';
import { BsWhatsapp } from 'react-icons/bs';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Meet Our Team',
    paragraph: 'Our team comprises of suitably qualified proffessionals.'
  };

  //Icon styles
  const socialIconStyles = { color: "white", color: "#6D6DFF", margin: "0 5px" };

  return (
    <section
      {...props}
      className={outerClasses}
      name = "about"
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">

                <h3 className="mt-0 mb-12 founder-center">
                  D.T Zirima
                </h3>
                <div className="text-xxs founder-roles fw-600 tt-u mb-8">
                  <div className="icon-container">
                    <BsBriefcase />
                  </div>
                  <span className="founder-portfolio">Full-Stack web developer, Mobile app developer, Blockchain developer</span>
                </div>

                <p className="m-0 founder-description">
                  One of our most priced resources at PinTech. Having worked on numerous projects before, he's a powerful team leader and a deliverer.
                </p>

                {/* The social media links element */}
                <div className="founder-social-container">
                  <a href="https://www.facebook.com/david.zirima.3" className="founder-social-link"><BsFacebook style={socialIconStyles} /></a>
                  <a href="https://www.linkedin.com/in/david-t-zirima-699b62163/" className="founder-social-link"><BsLinkedin style={socialIconStyles} /></a>
                  <a href="https://twitter.com/blackmhofu" className="founder-social-link"><BsTwitter style={socialIconStyles} /></a>
                </div>


              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/david.jpeg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">

                <h3 className="mt-0 mb-12 founder-center">
                  P Mungombe
                </h3>
                <div className="text-xxs founder-roles fw-600 tt-u mb-8">
                  <div className="icon-container">
                    <BsBriefcase />
                  </div>
                  <span className="founder-portfolio">Front-End web Developer, Graphic Designer,Social Media Advertising and Marketing</span>
                </div>

                <p className="m-0 founder-description">
                  Agility runs in his veins. A very proficient website designer with tones of experience in social media marketing and advertising as well.
                </p>

                {/* The social media links element */}
                <div className="founder-social-container">
                  <a href="https://www.facebook.com/iniesta.nkomo" className="founder-social-link"><BsFacebook style={socialIconStyles} /></a>
                  <a href="https://www.linkedin.com/in/proud-mungombe-13584a1ba/" className="founder-social-link"><BsLinkedin style={socialIconStyles} /></a>
                  <a href="https://twitter.com/MungombeProud" className="founder-social-link"><BsTwitter style={socialIconStyles} /></a>
                  <a href="https://www.instagram.com/proud_iniesta_nkomo_zw/" className="founder-social-link"><BsInstagram style={socialIconStyles} /></a>
                </div>


              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/proud.jpeg')}
                  alt="Features split 01"
                  width={528}
                  height={356} />
              </div>
            </div>
            <div className="split-item ">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">

                <h3 className="mt-0 mb-12 founder-center">
                  B.T Chimoyo
                </h3>
                <div className="text-xxs founder-roles fw-600 tt-u mb-8">
                  <div className="icon-container">
                    <BsBriefcase />
                  </div>
                  <span className="founder-portfolio">Full-stack web developer, System Architect, Data Analyst</span>
                </div>

                <p className="m-0 founder-description founder-description">
                  The data guy. He's fascinated about digitizing businesses by designing platforms that suit your day-to-day business activities.
                </p>

                {/* The social media links element */}
                <div className="founder-social-container">
                  <a href="https://www.facebook.com/bruce.chimoyo.58" className="founder-social-link"><BsFacebook style={socialIconStyles} /></a>
                  <a href="https://www.linkedin.com/in/bruce-chimoyo-2aa65720b/" className="founder-social-link"><BsLinkedin style={socialIconStyles} /></a>
                  <a href="https://twitter.com/takura_chimoyo" className="founder-social-link"><BsTwitter style={socialIconStyles} /></a>
                </div>


              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/bruce.jpeg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;